$.lui("comp",".comp_chart_advanced",function (selector) {
    let chart_elm = selector.find("[data-chart]");

    if(chart_elm.length) {
        let anim_duration = 300;

        $.importStyle(cdnjs.chartist_css);
        $.importScript(cdnjs.chartist, function () {
            let chart = new Chartist.Line(chart_elm.get(0), chart_elm.data("chart"), {
                width: 1200,
                height: 600,
                showArea: true,
                fullWidth: true,
                lineSmooth: Chartist.Interpolation.none({
                    fillHoles: false
                }),
                axisY: {
                    labelOffset: {
                        x: -10,
                        y: 5
                    },
                    scaleMinSpace: 50,
                    labelInterpolationFnc: function(value) {
                        if(Number.isInteger(value)) {
                            return value
                        }
                        else {
                            return (value.toString().slice(0,7) + "000").slice(0,7);
                        }
                    },
                },
                axisX: {
                    labelOffset: {
                        x: -30,
                        y: 20
                    },
                    labelInterpolationFnc: function(value, index) {
                        if(chart_elm.data("chart").series[0].length > 12) {
                            if(chart_elm.data("chart").series[0].length > 30) {
                                if(index === 0 || index === chart_elm.data("chart").series[0].length - 1 || index % 5 === 0) {
                                    return value;
                                }
                                else {
                                    return null;
                                }
                            }
                            else {
                                if(index === 0 || index === chart_elm.data("chart").series[0].length - 1 || index % 3 === 0) {
                                    return value;
                                }
                                else {
                                    return null;
                                }
                            }
                        }
                        else {
                            return value;
                        }
                    },
                },
                chartPadding: {
                    bottom: 0,
                    left: 10
                },
            });
            chart.on("draw",function (data) {
                if(data.type === 'point') {
                    let value = data.series[data.index];
                    if(!Number.isInteger(value)) {
                        value = (value.toString().slice(0,5) + "000").slice(0,5);
                    }
                    let group = new Chartist.Svg("g",null,'ct-bullet');
                    let circle1 = new Chartist.Svg("circle", {
                        cx: data.x,
                        cy: data.y,
                        r: 14,
                        style: "transform-origin:" + data.x + "px " + data.y + "px"
                    }, 'ct-outer');
                    circle1.animate({
                        opacity: {
                            begin: 1000,
                            dur: anim_duration,
                            from: 0,
                            to: 1
                        }
                    });
                    let circle2 = new Chartist.Svg("circle", {
                        cx: data.x,
                        cy: data.y,
                        r: 8,
                    }, 'ct-inner');
                    let text = new Chartist.Svg("text",{
                        x: data.x,
                        y: data.y - 30,
                    },'ct-label').text(value);

                    group.elem(circle1.getNode());
                    group.elem(circle2.getNode());
                    group.elem(text.getNode());
                    data.element.replace(group);
                }
                if(data.type === 'line' || data.type === 'area') {
                    data.element.animate({
                        d: {
                            begin: 500,
                            dur: 1000,
                            from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                }
            });
        });
    }
});